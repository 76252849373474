import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";

import serviciosAlarma1 from "../assets/images/serviciosAlarma1.png";
import serviciosAlarma2 from "../assets/images/serviciosAlarma2.png";
import serviciosAlarma3 from "../assets/images/serviciosAlarma3.png";
import serviciosAlarma1Mobile from "../assets/images/serviciosAlarma1Mobile.png";
import serviciosAlarma2Mobile from "../assets/images/serviciosAlarma2Mobile.png";
import serviciosAlarma3Mobile from "../assets/images/serviciosAlarma3Mobile.png";

import "../assets/css/slider.css";

export const SliderServices = (data, { handlerPopUpForm }) => {
  const [index, setIndex] = useState(0);
  const [text, setText] = useState("Alarma para casa");
  const phone = data.data.phoneNumber

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
    selectedIndex === 0
      ? setText("Alarma para casa")
      : selectedIndex === 1
      ? setText("Alarma para departamento")
      : setText("Alarma para tu negocio");
  };

  return (
    <div className="slider-title">
      <h2 className="slider-services-title">NOS ADAPTAMOS A TUS NECESIDADES</h2>

      {window.screen.width < 1001 ? (
        <h3>{text}</h3>
      ) : (
        <h3>
          Nuestros servicios de monitoreo se adaptan a las necesidades de tu
          hogar y familia
        </h3>
      )}
      <div className="carousel">
          <Carousel activeIndex={index} onSelect={handleSelect}>
            <Carousel.Item>
              {window.screen.width < 1001 ? (
                <a href={`tel:${phone}`}>
                  <img
                    src={serviciosAlarma1Mobile}
                    alt={text}
                    style={{ width: "100%", height: "130vw" }}
                  />
                </a>
              ) : (
                <a href={`tel:${phone}`}>
                  <img
                    src={serviciosAlarma1}
                    alt={text}
                    className="d-block w-100"
                  />
                </a>
              )}
            </Carousel.Item>
            <Carousel.Item>
              {window.screen.width < 1001 ? (
                <a href={`tel:${phone}`}>
                  <img
                    src={serviciosAlarma2Mobile}
                    alt={text}
                    style={{ width: "100%", height: "130vw" }}
                  />
                </a>
              ) : (
                <a href={`tel:${phone}`}>
                  <img
                    src={serviciosAlarma2}
                    alt={text}
                    className="d-block w-100"
                  />
                </a>
              )}
            </Carousel.Item>
            <Carousel.Item>
              {window.screen.width < 1001 ? (
                <a href={`tel:${phone}`}>
                  <img
                    src={serviciosAlarma3Mobile}
                    alt={text}
                    style={{ width: "100%", height: "130vw" }}
                  />
                </a>
              ) : (
                <a href={`tel:${phone}`}>
                  <img
                    src={serviciosAlarma3}
                    alt={text}
                    className="d-block w-100"
                  />
                </a>
              )}
            </Carousel.Item>
          </Carousel>
      </div>
    </div>
  );
};
