import React from "react";
import Carousel from "react-bootstrap/Carousel";

import promocionAlarma1 from "../assets/images/promocionAlarma1.png";
import promocionAlarma2 from "../assets/images/promocionAlarma2.png";
import promocionAlarma3 from "../assets/images/promocionAlarma3.png";
import promocionAlarma4 from "../assets/images/promocionAlarma4.png";
import promocionAlarma5 from "../assets/images/promocionAlarma5.png";
import promocionAlarma6 from "../assets/images/promocionAlarma6.png";
import promocionAlarma1Mobile from "../assets/images/promocionAlarma1Mobile.png";
import promocionAlarma2Mobile from "../assets/images/promocionAlarma2Mobile.png";
import promocionAlarma3Mobile from "../assets/images/promocionAlarma3Mobile.png";
import promocionAlarma4Mobile from "../assets/images/promocionAlarma4Mobile.png";
import promocionAlarma5Mobile from "../assets/images/promocionAlarma5Mobile.png";
import promocionAlarma6Mobile from "../assets/images/promocionAlarma6Mobile.png";

import "../assets/css/slider.css";

export const Slider = (data, { handlerPopUpForm }) => {
  const text = "Quiero la promo";
  const phone = data.data.phoneNumber

  return (
    <div className="slider-title">
      <h2 className="slider-promos-title">Aprovechá las promociones web</h2>

      {window.innerWidth < 1001 ? (
        <h3 style={{ display: "none" }}>{text}</h3>
      ) : (
        <h3>Aprovechá las promociones web</h3>
      )}

      <div className="carousel">
        <Carousel>
          <Carousel.Item>
            {window.innerWidth < 1001 ? (
              <a href={`tel:${phone}`}>
                <img
                  src={promocionAlarma1Mobile}
                  alt={text}
                  style={{ width: "100%", height: "130vw" }}
                />
              </a>
            ) : (
              <a href={`tel:${phone}`}>
                <img
                  src={promocionAlarma1}
                  alt={text}
                  className="d-block w-100"
                />
              </a>
            )}
          </Carousel.Item>
          <Carousel.Item>
            {window.innerWidth < 1001 ? (
              <a href={`tel:${phone}`}>
                <img
                  src={promocionAlarma2Mobile}
                  alt={text}
                  style={{ width: "100%", height: "130vw" }}
                />
              </a>
            ) : (
              <a href={`tel:${phone}`}>
                <img
                  src={promocionAlarma2}
                  alt={text}
                  className="d-block w-100"
                />
              </a>
            )}
          </Carousel.Item>
          <Carousel.Item>
            {window.innerWidth < 1001 ? (
              <a href={`tel:${phone}`}>
                <img
                  src={promocionAlarma3Mobile}
                  alt={text}
                  style={{ width: "100%", height: "130vw" }}
                />
              </a>
            ) : (
              <a href={`tel:${phone}`}>
                <img
                  src={promocionAlarma3}
                  alt={text}
                  className="d-block w-100"
                />
              </a>
            )}
          </Carousel.Item>
          <Carousel.Item>
            {window.innerWidth < 1001 ? (
              <a href={`tel:${phone}`}>
                <img
                  src={promocionAlarma4Mobile}
                  alt={text}
                  style={{ width: "100%", height: "130vw" }}
                />
              </a>
            ) : (
              <a href={`tel:${phone}`}>
                <img
                  src={promocionAlarma4}
                  alt={text}
                  className="d-block w-100"
                />
              </a>
            )}
          </Carousel.Item>
          <Carousel.Item>
            {window.innerWidth < 1001 ? (
              <a href={`tel:${phone}`}>
                <img
                  src={promocionAlarma5Mobile}
                  alt={text}
                  style={{ width: "100%", height: "130vw" }}
                />
              </a>
            ) : (
              <a href={`tel:${phone}`}>
                <img
                  src={promocionAlarma5}
                  alt={text}
                  className="d-block w-100"
                />
              </a>
            )}
          </Carousel.Item>
          <Carousel.Item>
            {window.innerWidth < 1001 ? (
              <a href={`tel:${phone}`}>
                <img
                  src={promocionAlarma6Mobile}
                  alt={text}
                  style={{ width: "100%", height: "130vw" }}
                />
              </a>
            ) : (
              <a href={`tel:${phone}`}>
                <img
                  src={promocionAlarma6}
                  alt={text}
                  className="d-block w-100"
                />
              </a>
            )}
          </Carousel.Item>
        </Carousel>
      </div>
    </div>
  );
};
