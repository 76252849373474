import React, { useState } from "react";
import { FormMessage } from "./FormMessage";
import axios from "axios";
import PhoneInput from "react-phone-number-input/input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useForm } from "../hooks/useForm";
import "../assets/css/popUpForm.css";

export const PopUpForm = ({ text }) => {
  const initialForm = {
    fullName: "",
    email: "",
  };

  const [formValues, handleInputChange, reset] = useForm(initialForm); // Hook para uso del custom hook useForm
  const [correctValidation, setCorrectValidation] = useState(0);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [popup, setPopup] = useState("popup popuptext");
  const [valueCheck, setValueCheck] = useState(false);
  const [loading, setLoading] = useState(false);

  // Tomo valores de la query
  const utms = window.location.search.substring(1).split("&");

  const utm_source = utms[0] ? utms[0].split("=").pop() : "Organic";
  const utm_medium = utms[1] ? utms[1].split("=").pop() : "Organic";
  const utm_campaign = utms[2] ? utms[2].split("=").pop() : "Organic";
  const utm_term = utms[3] ? utms[3].split("=").pop() : "Organic";
  const utm_content = utms[4] ? utms[4].split("=").pop() : "Organic";

  const validar_email = (email) => {
    if (/^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(email)){
      return true
    } else {
      return false
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault(); // Evito que se recargue la pagina
    setLoading(true);

    // Verifico si el email existe.
    const email_validado = validar_email(formValues.email)
    if (email_validado !== true){
      setCorrectValidation(3);
      setLoading(false);
      setTimeout(() => {
        setCorrectValidation(0);
      }, 4000);
      return false;
    }

    let lead = {
      name: formValues.fullName,
      phone: phoneNumber,
      whatsapp: phoneNumber,
      email: formValues.email,
      utmSource: utm_source,
      leadProduct: "ADT_AR",
      leadSource: "Google",
      utmMedium: utm_medium,
      utmCampaign: utm_campaign,
      utmTerm: utm_term,
      utmContent: utm_content,
      state: "",
    };

    // Si todo esta correcto, lo envio
    if (
      formValues.fullName &&
      isValidPhoneNumber(phoneNumber) &&
      email_validado &&
      valueCheck
    ) {
      await axios({
        method: "POST",
        url: "processor.php",
        headers: {
          "Content-Type": "application/json",
        },
        data: lead,
      })
        .then((res) => {
          if (res.data.includes("success")) {
            setCorrectValidation(1); // Seteo la variable en 1 para que en la pagina se muestre el mensaje de "Uno de nuestros representantes lo contactará a la brevedad."
            setLoading(false);
          } else {
            setCorrectValidation(5);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.warn(err);
          setCorrectValidation(5); // "A ocurrido un error inesperado"
          setLoading(false);
        });

      setTimeout(() => {
        setCorrectValidation(0);
      }, 4000);
      reset();
      setValueCheck(!valueCheck);
      setPhoneNumber();
      return;
    }

    // Verifico si el teléfono es válido, sino, aparece mensaje de error
    if (!isValidPhoneNumber(phoneNumber)) {
      setCorrectValidation(2);
      setLoading(false);
      setTimeout(() => {
        setCorrectValidation(0);
      }, 4000);
      return;
    }
  };

  const handlerPopup = () => {
    popup === "popup popuptext"
      ? setPopup("popup popuptext-show")
      : setPopup("popup popuptext");
  };

  return (
    <div className="popUpForm-title">
      <div className="popUpForm">
        <div className="popUpForm-message">
          <FormMessage correctValidation={correctValidation} />
        </div>
        <h2>{text}</h2>
        <h3>DEJÁ TUS DATOS Y TE ASESORAMOS</h3>

        <form className="contact-form" onSubmit={handleSubmit}>
          <label htmlFor="fullName"> Nombre y apellido *</label>
          <input
            type="text"
            name="fullName"
            id="fullName"
            value={formValues.fullName}
            onChange={handleInputChange}
            required
          />
          <label htmlFor="phoneNumber"> Teléfono de contacto *</label>
          <PhoneInput
            defaultCountry="AR"
            placeholder="+54 261 5863425"
            name="phoneNumber"
            value={phoneNumber}
            onChange={setPhoneNumber}
            required
          />

          <label htmlFor="email"> E-mail *</label>
          <input
            type="email"
            name="email"
            id="email"
            value={formValues.email}
            onChange={handleInputChange}
            required
          />

          <div className="checkForm">
            <div className="conditions" onClick={handlerPopup}>
              <label htmlFor="checkbox" className="checkLabel">
                Acepto términos y condiciones
              </label>
            </div>
            <div className="checkbox-container">
              <input
                type="checkbox"
                id="check"
                checked={valueCheck}
                value={valueCheck}
                onChange={() => setValueCheck(!valueCheck)}
                required
              />
              <span id="popup" className={popup} onClick={handlerPopup}>
                TÉRMINOS Y CONDICIONES DEL SERVICIO DE MONITOREO Oferta válida
                desde 01/01/2021 hasta el 31/12/2021, únicamente para la
                contratación de nuevas cuentas Pack Gold, Pack Premium y Pack
                Platinum que sean adheridas al débito automático por tarjeta de
                crédito (consultar tarjetas habilitadas). Corresponde al costo
                de instalación del equipo. Cuyos equipos serán entregados en
                comodato. Corresponde a $18,91 por día durante los primeros tres
                meses de activación. La aplicación de dicho descuento se
                realizara al cierre del resumen en el cual se registre la
                operación, o en hasta dos (2) resúmenes de cuenta. Para acceder
                a los beneficios contáctese al 0810-555-1313. Sujetos a
                disponibilidad técnica y cobertura geográfica del servicio
                (consultar en 0810-555-1313). La presentes promociones no son
                acumulable con otras. Términos y condiciones del servicio en
                www.adt.com.ar. ADT Security Services S.A. CUIT 30-65663161-5,
                Thames 139, Boulogne, Provincia de Buenos Aires. © 2021. ADT
                Security Services S.A. Quedan todos los derechos reservados.
              </span>
            </div>
          </div>

          {loading ? (
            <button type="submit" disabled>
              <span
                className="spinner-border spinner-border-md"
                role="status"
                aria-hidden="true"
              ></span>
              {"      "}
              Cargando...
            </button>
          ) : (
            <button type="submit" value="Solicitar asesoramiento">
              Solicitar asesoramiento
            </button>
          )}
        </form>
      </div>
    </div>
  );
};
