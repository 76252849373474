import React from "react";
import shield from "../assets/images/shield.svg";
import light from "../assets/images/light.svg";
import lock from "../assets/images/lock.svg";
import lockNoBackground from "../assets/images/lockNoBackground.svg";
import "../assets/css/services.css";

export const Services = (data) => {
  const phone = data.data.phoneNumber
  return (
    <div className="services">
      <h2>Te ofrecemos:</h2>
      <div className="items-container">
        <div className="item">
          <img src={shield} alt="Integral protection" />
          <div className="item-info">
            <h3>Protección integral</h3>
            <p>
              Ofrecemos soluciones de protección para hogares y empresas con
              atención personalizada.
            </p>
          </div>
        </div>

        <div className="item">
          <img src={light} alt="Alarm monitoring" />
          <div className="item-info">
            <h3>Monitoreo de alarmas</h3>
            <p>
              Con nuestro sistema podremos saber qué pasó en tu hogar o negocio
              y realizar las acciones necesarias de seguridad / protección.
            </p>
          </div>
        </div>

        <div className="item">
          <img src={lock} alt="Guaranteed security" />
          <div className="item-info">
            <h3>Seguridad garantizada</h3>
            <p>
              Tenemos la mas alta tecnología junto con los mejores especialistas
              en seguridad para ofrecerte un servicio adecuado a tus
              necesidades.
            </p>
          </div>
        </div>
      </div>
      <div className="service-footer">
        <p>
          Invertimos en el mejor equipamiento para la{" "}
          <b>seguridad de tu hogar</b> y <b>locales comerciales</b>
        </p>
        <img src={lockNoBackground} alt="Lock" />
          <a href={`tel:${phone}`} style={{textDecoration: 'none'}}>
            <button type="button">Contratá ahora</button>
          </a>
      </div>
    </div>
  );
};
